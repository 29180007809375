import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')


const searchAppCategories = createAsyncThunk('searchAppCategories/fetch', async(searchTerm ) => {
 // console.log("searchAppCategories thunk", searchTerm)
 
  
  // return fetch(KEYS.GRAPHQL_URL, {
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //         variables: {
  //         searchTerm
  //       },
  //         query: `
  //         query SearchAppCategories($searchTerm: String) {
  //         searchAppCategories(searchTerm: $searchTerm) {
  //           name
  //           id
  //         }
  //       }
  //       `
  //     })
  // }).then(res => res.json())


  return categories.filter(category => 
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


})

export { searchAppCategories }


const categories = [
        {
          "id": "662efc0b1b12fa7abef50f5a",
          "name": "Artificial Intelligence (AI)"
        },
        {
          'id': '674264a8180100321e20ad20', 
          'name': 'Fediverse'
        },
        {
          "id": "662efd0d1b12fa7abef50f5b",
          "name": "Productivity"
        },
        {
          "id": "662efd1b1b12fa7abef50f5c",
          "name": "Creator Economy"
        },
        {
          "id": "662efd4e1b12fa7abef50f5d",
          "name": "Social Networking"
        },
        {
          "id": "662efd5d1b12fa7abef50f5e",
          "name": "E-commerce"
        },
        {
          "id": "662efd691b12fa7abef50f5f",
          "name": "Marketplace"
        },
        {
          "id": "662efd751b12fa7abef50f60",
          "name": "Freelancing"
        },
        {
          "id": "662efd891b12fa7abef50f61",
          "name": "Professional Networking"
        },
        {
          "id": "662efda81b12fa7abef50f62",
          "name": "News & Media"
        },
        {
          "id": "662efdc01b12fa7abef50f63",
          "name": "Social Media"
        },
        {
          "id": "662efdcb1b12fa7abef50f64",
          "name": "Communication"
        },
        {
          "id": "662efdd61b12fa7abef50f65",
          "name": "Video Streaming"
        },
        {
          "id": "662efde41b12fa7abef50f66",
          "name": "Music Streaming"
        },
        {
          "id": "662efdef1b12fa7abef50f67",
          "name": "Audiobooks"
        },
        {
          "id": "662efdfd1b12fa7abef50f68",
          "name": "Navigation"
        },
        {
          "id": "662efe0a1b12fa7abef50f69",
          "name": "Travel"
        },
        {
          "id": "662efe1d1b12fa7abef50f6a",
          "name": "Accommodation Booking"
        },
        {
          "id": "662efe821b12fa7abef50f6b",
          "name": "Ride-Sharing"
        },
        {
          "id": "662effeb1b12fa7abef50f6c",
          "name": "Web Browser"
        },
        {
          "id": "662efffb1b12fa7abef50f6d",
          "name": "Email Service"
        },
        {
          "id": "662f00061b12fa7abef50f6e",
          "name": "Cloud Storage"
        },
        {
          "id": "662f00111b12fa7abef50f6f",
          "name": "Office Suite"
        },
        {
          "id": "662f001f1b12fa7abef50f70",
          "name": "Video Conferencing"
        },
        {
          "id": "662f003b1b12fa7abef50f71",
          "name": "Business Collaboration"
        },
        {
          "id": "662f006b1b12fa7abef50f72",
          "name": "Graphic Design"
        },
        {
          "id": "662f008e1b12fa7abef50f73",
          "name": "Education"
        },
        {
          "id": "662f00a51b12fa7abef50f74",
          "name": "Mental Wellness"
        },
        {
          "id": "662f00b31b12fa7abef50f75",
          "name": "Meditation"
        },
        {
          "id": "662f01131b12fa7abef50f76",
          "name": "Online Learning"
        },
        {
          "id": "662f011e1b12fa7abef50f77",
          "name": "E-books"
        },
        {
          "id": "662f01281b12fa7abef50f78",
          "name": "Financial Services"
        },
        {
          "id": "662f013f1b12fa7abef50f79",
          "name": "Payment System"
        },
        {
          "id": "662f01c01b12fa7abef50f7a",
          "name": "Cryptocurrency"
        },
        {
          "id": "662f01cb1b12fa7abef50f7b",
          "name": "Investment Management"
        },
        {
          "id": "662f01df1b12fa7abef50f7c",
          "name": "Food Delivery"
        },
        {
          "id": "662f01f21b12fa7abef50f7d",
          "name": "Grocery Delivery"
        },
        {
          "id": "662f02001b12fa7abef50f7e",
          "name": "Search Engine"
        },
        {
          "id": "662f02441b12fa7abef50f7f",
          "name": "Community Forum"
        },
        {
          "id": "662f02721b12fa7abef50f80",
          "name": "Inspiration & Creativity"
        },
        {
          "id": "662f02a21b12fa7abef50f81",
          "name": "Gaming"
        },
        {
          "id": "66300789765574d28f798f40",
          "name": "Gig Economy"
        },
        {
          "id": "66724829f020c7b6664dbc18",
          "name": "Entertainment"
        }
      ]
