const allCategories = [
  { "id": 1, "name": "Artificial Intelligence (AI)" },
  { "id": 2, "name": "Productivity" },
  { "id": 3, "name": "Creator Economy" },
  { "id": 4, "name": "Social Networking" },
  { "id": 5, "name": "E-commerce" },
  { "id": 6, "name": "Marketplace" },
  { "id": 7, "name": "Freelancing" },
  { "id": 8, "name": "Professional Networking" },
  { "id": 9, "name": "News" },
  { "id": 10, "name": "Media" },
  { "id": 11, "name": "Social Media" },
  { "id": 12, "name": "Communication" },
  { "id": 13, "name": "Video Streaming" },
  { "id": 14, "name": "Music Streaming" },
  { "id": 15, "name": "Audiobooks" },
  { "id": 16, "name": "Navigation" },
  { "id": 17, "name": "Travel" },
  { "id": 18, "name": "Accommodation Booking" },
  { "id": 20, "name": "Ride-Sharing" },
  { "id": 21, "name": "Web Browser" },
  { "id": 22, "name": "Email Service" },
  { "id": 23, "name": "Cloud Storage" },
  { "id": 24, "name": "Office Suite" },
  { "id": 25, "name": "Video Conferencing" },
  { "id": 26, "name": "Business Collaboration" },
  { "id": 27, "name": "Graphic Design" },
  { "id": 29, "name": "Education" },
  { "id": 30, "name": "Mental Wellness" },
  { "id": 31, "name": "Meditation" },
  { "id": 32, "name": "Online Learning" },
  { "id": 34, "name": "E-books" },
  { "id": 35, "name": "Financial Services" },
  { "id": 36, "name": "Payment System" },
  { "id": 38, "name": "Cryptocurrency" },
  { "id": 39, "name": "Investment Management" },
  { "id": 40, "name": "Food Delivery" },
  { "id": 42, "name": "Grocery Delivery" },
  { "id": 43, "name": "Search Engine" },
  { "id": 45, "name": "Content Management" },
  { "id": 46, "name": "Community Forum" }, //redit
  { "id": 47, "name": "Inspiration & Creativity" },
  { "id": 48, "name": "Gaming/Media" },
  { "id": 49, "name": "Fediverse" },
]


module.exports = { allCategories };