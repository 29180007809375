// LoginWithEmailPassword.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { empLogInWithEmailAndPassword } from '../../store'
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"; 


const LoginWithEmailPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasEmailError, setEmailError] = useState(false);
  
  
const { isLoading, isAuthenticated, errorMessage, hasError } = useSelector((state) => state.auth)

   useEffect(() => {
    if (isAuthenticated) {
       // Get the 'from' path from location state if it exists, otherwise default to "/"
        let redirectTo = '/';
        if (location.state && location.state.from && location.state.from.pathname) {
          redirectTo = location.state.from.pathname;
        }
        navigate(redirectTo, { replace: true });
        //navigate('/');
    }
  }, [isAuthenticated, navigate]);

   // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      //console.log("handleSubmit")

        dispatch(empLogInWithEmailAndPassword({email, password}))
    };

   // Regular expression for basic email validation
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email.trim());
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        // Check if the input email is in a valid format
        if (validateEmail(inputEmail) || inputEmail === "") {
            setEmailError(false);
        } else {
            // Optionally, you could provide feedback to the user here
            //console.log("Invalid email format");
            setEmailError(true);
        }
    };


    // Function to check if there are any changes
      const hasChanges = () => {
        return (
          email !== "" &&
          password !== "" && !hasEmailError
        );
      };

  return (
    <div>
    <Helmet>
            <title>{"Meeako Admin | Login"}</title>
     </Helmet>
    <div className="wrapper">     
    <h2 className="ui center aligned header">Login</h2>
        <div className={isLoading ? "ui raised very padded text container segment loading" : "ui raised very padded text container segment"}>
            
              <form className={"ui form"}>
                  <div className={hasEmailError ? "field error" : "field"}>
                  <h3>
                     Email
                    </h3>
                    <div className="ui left icon input">
                      <i className="user icon"></i>
                     <input type="text" 
                      autoComplete="email"
                     value={email} 
                     onChange={(e) => setEmail(e.target.value)}
                     onBlur={handleEmailChange} />
                     </div>
                  </div>

                  <div className="field">
                    <h3>
                     Password
                    </h3>
                      <div className="ui left icon input">
                         <i className="lock icon"></i>
                          <input type="password" value={password}
                           autoComplete="current-password"
                           onChange={(e) => setPassword(e.target.value)} />
                      </div>    
                  </div>
                  <br/>
                  <div className="field">
                      {hasError && <p style={ {fontSize: "1em", color: '#888' } }>{errorMessage}</p>}
                     <button disabled={!hasChanges()} className="fluid ui teal circular button" type="submit" onClick={handleSubmit}>Login</button>
                   </div>  
              </form>
        </div>
     </div>
    </div>   
  );
};


export default LoginWithEmailPassword




