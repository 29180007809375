import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchInitialiOSApps = createAsyncThunk('fetchInitialiOSApps/fetch', async(term) =>{
  //console.log("fetchInitialiOSApps")
  //console.log(KEYS.WEBAPPS_PUBLISH_STATUS)

	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        iOSappsPublishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `query ($iOSappsPublishStatus: String) {
                 iosapps(publishStatus: $iOSappsPublishStatus) {
                  __typename
                  name
                  id
                  description
                  logo_url
                  initial_image_url
                  categories {
                    id
                    name
                }
                }
              }`	
    })
  }).then(res => res.json())
    //console.log(response)
})


const fetchiOSAppById = createAsyncThunk('fetchiOSAppById/fetch', async(term) =>{
  //console.log("fetchiOSAppById")
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //variables: variables
      query: `{
              appById (appId: "${term}") {
                ... on iOS {
                      __typename
                      name
                      id
                      description
                      logo_url
                      initial_image_url
                      categories {
                        id
                        name
                      }
                      productInsight {
                                      productText
                                      valueText
                                      shouldDisplay
                                      monetizationText
                                      competitorText
                                      userSegmentText
                                      missionText
                                    } 
                     competitors {
                              ... on iOS {
                                  __typename
                                  id
                                  name
                                  href
                              }
                              ... on Web {
                                  __typename
                                  id
                                  name
                                  href
                            }
                       }
                    }
                  }
            }`  
    })
  }).then(res => res.json())
    //console.log(response)
})

const fetchProductInsightForiOSApp = createAsyncThunk('fetchProductInsightForiOSApp/fetch', async(term) =>{
  //console.log("fetchProductInsightForiOSApp")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term
      },
      query: `query ProductInsightAppById($appId: ID!) {
                                              productInsightAppById(appId: $appId) {
                                              shouldDisplay
                                              productText
                                              userSegmentText
                                              valueText
                                              monetizationText
                                              competitorText
                                              missionText
                                            }
                                          }`  
    })
  }).then(res => res.json())
    console.log(response)
})

//NOTE: using to update both IOS and WEB APP
const updateiOSAppByID = createAsyncThunk('updateiOSAppByID/update', async({ appId, iOsApp }) => {
  //console.log("updateiOSAppByID")
  //console.log(appId)
  //console.log(iOsApp)

  // Determine which fields are being updated
    const updatedFields = Object.keys(iOsApp);

    // Construct the GraphQL mutation response fields dynamically based on the updated fields
    const responseFields = updatedFields.map(field => `
      ${field}
    `).join(' ');

    //console.log(responseFields)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          iOsApp,
        },
          query: `
          mutation UpdateiOSAppByID($appId: ID!, $iOsApp: updateiOSApp) {
            updateiOSAppByID(appId: $appId, iOSApp: $iOsApp) {
              ${responseFields}
          }
        }
        `
      })
  }).then(res => res.json())
})


//NOTE: using to update both IOS and WEB APP
const updateAppCategoriesiOSAppByID = createAsyncThunk('updateAppCategoriesiOSAppByID/update', async({ appId, iOsApp }) => {
  //console.log("updateAppCategoriesiOSAppByID")


  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          iOsApp,
        },
          query: `
          mutation UpdateiOSAppByID($appId: ID!, $iOsApp: updateiOSApp) {
            updateiOSAppByID(appId: $appId, iOSApp: $iOsApp) {
              id
              categories {
                  id
                  name
                }
          }
        }
        `
      })
  }).then(res => res.json())
})


//NOTE: using to update both IOS and WEB APP
const updateAppMonetizationsiOSAppByID = createAsyncThunk('updateAppMonetizationsiOSAppByID/update', async({ appId, iOsApp }) => {
  // console.log("updateAppMonetizationsiOSAppByID")
  // console.log(appId)
  // console.log(iOsApp)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          iOsApp,
        },
          query: `
          mutation UpdateiOSAppByID($appId: ID!, $iOsApp: updateiOSApp) {
            updateiOSAppByID(appId: $appId, iOSApp: $iOsApp) {
              id
              monetizations {
                id
                name
                priceRange
              }
          }
        }
        `
      })
  }).then(res => res.json())
})



//NOTE: using to update both IOS and WEB APP
const updateAppCompetitorsiOSAppByID = createAsyncThunk('updateAppCompetitorsiOSAppByID/update', async({ appId, iOsApp }) => {
  //console.log("updateAppCompetitorsiOSAppByID")


  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          iOsApp,
        },
          query: `
          mutation UpdateiOSAppByID($appId: ID!, $iOsApp: updateiOSApp) {
            updateiOSAppByID(appId: $appId, iOSApp: $iOsApp) {
              id
              competitors {
                   ... on iOS {
                              __typename
                                id
                                name
                                href
                          }
                     ... on Web {
                            __typename
                              id
                              name
                              href
                        }

                }
          }
        }
        `
      })
  }).then(res => res.json())
})


const updateSearchTermsByAppId = createAsyncThunk('updateSearchTermsByAppId/update', async({ appId, searchTerms }) => {
  //console.log("updateSearchTermsByAppId")
  //console.log(appId)
  //console.log(searchTerms)


  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          appId,
          searchTerms,
        },
          query: `
          mutation updateSearchTermsByAppId($appId: ID!, $searchTerms: [String]!) {
            updateSearchTermsByAppId(appId: $appId, searchTerms: $searchTerms) {
               ... on iOS {
                __typename
                searchTerms
              }
              ... on Web {
                __typename
                searchTerms
              }
          }
        }
        `
      })
  }).then(res => res.json())
})


const createiOSApp = createAsyncThunk('createiOSApp/create', async({ iOsApp }) => {
  //console.log("createiOSApp")
  //console.log(iOsApp)

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          iOsApp,
        },
          query: `
          mutation createiOSApp($iOsApp: iOSAppInput) {
            createiOSApp(iOSApp: $iOsApp) {
              __typename
              id
              name
          }
        }
        `
      })
  }).then(res => res.json())
})


const fetchIOSCompetitorsByAppId = createAsyncThunk('fetchIOSCompetitorsByAppId/fetch', async(term) =>{
  //console.log("fetchIOSCompetitorsByAppId")
  return fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
        appId: term,
        publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `query findCompetitorsByAppId($appId: ID!, $publishStatus: String) {
      findCompetitorsByAppId(appId: $appId, publishStatus: $publishStatus) {
                      ... on iOS{
                        id
                        name
                        href
                        publishStatus
                      },
                      ... on Web{
                        id
                        name
                        href
                        publishStatus
          }
        }
      }`
    })
  }).then(res => res.json())
    //console.log(response)

})

export { updateSearchTermsByAppId, 
        fetchInitialiOSApps, 
        fetchiOSAppById, 
        fetchProductInsightForiOSApp, 
        updateiOSAppByID,
        createiOSApp,
        updateAppCategoriesiOSAppByID,
        updateAppCompetitorsiOSAppByID,
        fetchIOSCompetitorsByAppId,
        updateAppMonetizationsiOSAppByID
      }




